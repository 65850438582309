.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.site-layout-content {
  min-height: 280px;
  padding: 10px;
  height: 100%;
  position: relative;
}
.site-layout-content iframe.airtable-embed {
  z-index: 1;
  position: relative;
}
.logo {
  float: left;
  width: 150px;
  height: 31px;
  margin: 16px 0;
  display: flex;
}
.logo img {
  height: 100%;
  width: 100%;
}
.menu-container li[style*="order: 3;"] {
  padding-inline-end: 0px !important;
}
.menu-container li[style*="order: 3;"]::after {
  border-bottom-width: 0px !important;
}
.menu-container li[style*="order: 2;"]::after {
  border-bottom-width: 0px !important;
}
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;
}
.about-page-container {
  margin: 0 10%;
  overflow-y: scroll;
  max-height: 100%;
}

